export default {
  keycloak: {
    url: "https://sso.tino-dtb.info/auth",
    realm: "comjoo-hub",
    clientId: "hub-connect",
  },
  userClient: {
    url: "https://user-api.tino-dtb.info",
  },
  baseURL: "https://im-api.tino-dtb.info",
  fhirServerUrl: "https://fhir.tino-dtb.info",
  easyUrl: "https://pm-easy.tino-dtb.info",
  caseManagement: "https://signup.api.comjoo.info",
  appLinks: {
    android: "https://play.google.com/store/apps/details?id=com.comjoo.easy",
    ios: "https://apps.apple.com/de/app/comjoodoc-easy/id1485413971",
  },
};
