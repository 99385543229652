import React, { Fragment } from 'react';
// import { NavLink } from "react-router-dom"; //Link, Redirect
import { Button, Icon, Menu } from 'antd'; // Icon, Menu
// translation
import { useTranslation } from 'react-i18next';
import LanguageSwitch from './LanguageSwitch';
// import { withRouter } from "react-router";
// UI
import './HeaderContent.scss';
// import menuItems from './nav';
import comjooLogo from './../../../assets/logo-comjoodoc-easy-lang-color-white.png';
import sanecumLogo from './../../../assets/sanecum-logo-white.svg';
import tinodtbLogo from './../../../assets/logo-tino-dtb.svg'
import { Link } from 'react-router-dom';
const { SubMenu } = Menu;

const logos: any = {
  tinodtb: {
    logoPath: tinodtbLogo,
    width: 102,
    title: 'tinodtb.title',
  },
  sanecum: {
    logoPath: sanecumLogo,
    width: 52,
    title: 'sanecum.title',
  },
  comjoo: {
    logoPath: comjooLogo,
    width: 120,
    title: 'title',
  },
};

interface Props {
  //history:any;
  isAuthenticated: boolean;
  onChange: any;
}

const HeaderContent = (props: Props) => {
  const [t] = useTranslation();
  const styles = {
    width: '400px',
    lineHeight: '60px',
    backgroundColor: 'transparent',
  };
  /**
   * return section { content } html element

  const [t] = useTranslation();
  const styles = {
    lineHeight: '60px',
    backgroundColor: 'transparent'
  };

  const nav = [
    {
      class: 'nav-link',
      activeClass: 'ant-menu-item-selected',
      src: '/dashboard',
      text: 'Dashboard',
      auth: true
    },
    {
      class: 'nav-link',
      activeClass: 'ant-menu-item-selected',
      src: '/signin',
      text: 'Login',
      auth: false
    },
    {
      class: 'nav-link',
      activeClass: 'ant-menu-item-selected',
      src: '/register',
      text: 'Register',
      auth: true
    }
  ];
  */
  function logout() {
    props.onChange();
  }

  const { SubMenu, Item, ItemGroup } = Menu;
  const  handleClickProfileButton =()=>{}
  return (
    <Fragment>
     <div style={{ width: "100%", display:'flex', justifyContent:'space-between' }}>
     <div className="logo" style={{width:'200px', height:'60px', overflow:'hidden'}}>
      <Link to="/">
        <img
        src={tinodtbLogo}
        alt="logo"
        style={{
          width: logos[process.env.REACT_APP_THEME_ENV].width,
          marginTop: '-7px',
        }}
        />
     </Link>
      </div>
      <div className="menuuser" style={{
        width: '400px',
        lineHeight: '60px',
        backgroundColor: 'transparent',
        float:'right',
        overflow:'hidden'
      }}>
        {/*<LanguageSwitch />*/}
        {props.isAuthenticated ? (
          <Button onClick={logout} type="primary" icon="poweroff">
            <span className="logout-btn">{t('header.btn_logout')}</span>
          </Button>
        ) : null}
        <Menu mode={'horizontal'}  theme="dark">
          <SubMenu
            style={{float:'right', background:'none'}}
            title={
              <span className="submenu-title-wrapper"  style={{float:'right', background:'none'}}>
              <Icon type="question-circle" />         
              </span>
            }>
          
            <Menu.Item key="imprint">
              <Link to="/imprint">
              <Icon className='dtb-menu-icon' type="solution" /> Impressum
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
        
      </div>
     
      {/**
      <Menu theme="dark" mode="horizontal" style={styles}>
          {
            nav.map((item: any, index) => {
              if(props.isAuthenticated && item.text !== 'Login') {
                if(props.isAuthenticated) {
                  return <Menu.Item key={index}>
                  <NavLink  to={item.src}>{item.text}</NavLink>
                </Menu.Item>
                } else { return null }
              } else {
                if(!props.isAuthenticated) {
                  return <Menu.Item key={index}>
                    <NavLink  to={item.src}>{item.text}</NavLink>
                  </Menu.Item>
                } else { return null }
              }
            })
          }
      </Menu>
     */}
      {/* menuItems <Link to="/logout">logout</Link> */}
      </div>
    </Fragment>
  );
};

export default HeaderContent;
