export default {
  keycloak: {
    url: "https://sso.comjoo.net/auth",
    realm: "comjoo-hub",
    clientId: "hub-connect",
  },
  userClient: {
    url: "https://user-api.tino-dtb.info",
  },
  baseURL: "https://im.api.comjoo.net",
  fhirServerUrl: "https://test-server.fhir.medworx.io",
  easyUrl: "https://pm-easy.api.comjoo.net",
  caseManagement: "https://signup.api.comjoo.net",
  appLinks: {
    android: "https://play.google.com/store/apps/details?id=com.comjoo.easy",
    ios: "https://apps.apple.com/de/app/comjoodoc-easy/id1485413971",
  },
};
